import React from "react";

import { Container, FlexGrid, H2 } from "@util/standard";
import { assets } from "@util/constants";
import { Carousel } from "@global";
import { pageWidthMargin } from "@util/helper";
import ProductPreview from "@shared/productPreview";
import {
  CarouselLeftArrow,
  RelatedCollectionContainer,
} from "@shared/shared.styles";
import TextButton from "@shared/textButton";
import { useCheckScreenWidth } from "@util/hooks";
import { useGiftingProductsQuery } from "@api";

const NextIcon = (
  <CarouselLeftArrow>
    <img src={assets.arrowRight} alt="right-arrow" />
  </CarouselLeftArrow>
);

const PrevIcon = (
  <CarouselLeftArrow rightArrow>
    <img src={assets.arrowRight} alt="left-arrow" />
  </CarouselLeftArrow>
);

const GiftsCarousel = () => {
  const { isTabletWidth, isMobileWidth } = useCheckScreenWidth();
  const { allSanityGiftingLandingPage } = useGiftingProductsQuery();

  const products = allSanityGiftingLandingPage?.nodes?.[0]?.products;
  const margin = pageWidthMargin();
  if (!products?.length) return null;
  return (
    <Container
      position="relative"
      width={`calc(100% - ${margin} - ${margin})`}
      margin="120px auto"
      tabletMargin="20px auto 60px"
      flexDirection="column"
    >
      <Container margin="0 0 1.5em" alignItems="flex-end">
        <H2 margin="0 .5em 0 0">Other Gifts</H2>
        <TextButton linkTo="/gifting" linkText="Back to Shop" />
      </Container>
      {isTabletWidth || isMobileWidth ? (
        <RelatedCollectionContainer flexDirection="column" width="100%">
          <Carousel
            hideArrow={false}
            hideDots={true}
            nextIcon={NextIcon}
            prevIcon={PrevIcon}
            variableWidth={true}
            infinite={false}
          >
            {products &&
              products.slice(0, 3).map(product => {
                if (!product) return;
                return (
                  <ProductPreview
                    key={product.id}
                    noMargin={true}
                    product={product}
                    isGift
                    isCarousel
                  />
                );
              })}
          </Carousel>
        </RelatedCollectionContainer>
      ) : (
        <FlexGrid
          gridMargin="25px"
          laptopGridMargin="20px"
          tabletGridMargin="12px"
        >
          {products &&
            products.slice(0, 3).map(product => {
              if (!product) return;
              return (
                <ProductPreview
                  key={product.id}
                  noMargin={true}
                  product={product}
                  isGift
                />
              );
            })}
        </FlexGrid>
      )}
    </Container>
  );
};

export default GiftsCarousel;
